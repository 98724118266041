import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

const Login = lazy(() => import('src/pages/login'));
const AppShell = lazy(() => import('src/components/app-shell'));
const AuthLayout = lazy(() => import('src/components/auth-layout'));
const Applications = lazy(() => import('src/pages/applications'));
const EndorsementList = lazy(() => import('src/pages/endorsement-list'));
const Overview = lazy(() => import('src/pages/overview'));
const Operations = lazy(() => import('src/pages/operations'));
const Equipments = lazy(() => import('src/pages/equipments'));
const Drivers = lazy(() => import('src/pages/drivers'));
const Safety = lazy(() => import('src/pages/safety'));
const Financials = lazy(() => import('src/pages/financials'));
const Losses = lazy(() => import('src/pages/losses'));
const Packages = lazy(() => import('src/pages/packages'));
const Endorsement = lazy(() => import('src/pages/endorsement'));
const NFAppShell = lazy(() => import('src/pages/nf-application'));
const PendingApplications = lazy(
  () => import('src/pages/pending-applications'),
);
const GoogleAuthRedirect = lazy(
  () => import('src/pages/oAuth/google-redirect'),
);
const AdmittedApplication = lazy(
  () => import('src/pages/admitted-application'),
);

export const routes: RouteObject[] = [
  { path: '/', element: <Login /> },
  { path: '/applications', element: <Navigate to="/fleet/applications" /> },
  {
    path: '/fleet/applications',
    element: <AuthLayout component={Applications} />,
  },
  {
    path: 'fleet/pending-applications',
    element: <AuthLayout component={PendingApplications} />,
  },
  {
    path: '/fleet/endorsements',
    element: <AuthLayout component={EndorsementList} />,
  },
  {
    path: '/fleet/endorsements/:endorsementReviewId',
    element: <AuthLayout component={Endorsement} />,
  },
  {
    path: '/non-fleet/endorsements/:endorsementReviewId',
    element: <AuthLayout component={Endorsement} />,
  },
  {
    path: '/applications/:appReviewId',
    element: <AuthLayout component={AppShell} />,
    children: [
      { path: 'Overview', element: <Overview /> },
      { path: 'operations', element: <Operations /> },
      { path: 'equipments', element: <Equipments /> },
      { path: 'drivers', element: <Drivers /> },
      { path: 'safety', element: <Safety /> },
      { path: 'financials', element: <Financials /> },
      { path: 'losses', element: <Losses /> },
      { path: 'packages', element: <Packages /> },
    ],
  },
  {
    path: '/non-fleet/applications',
    element: <AuthLayout component={Applications} />,
  },
  {
    path: '/non-fleet/endorsements',
    element: <AuthLayout component={EndorsementList} />,
  },
  {
    path: '/non-fleet/nrb/:appReviewId',
    element: <AuthLayout component={NFAppShell} />,
  },
  {
    path: '/non-fleet/admitted/:appReviewId',
    element: <AuthLayout component={AdmittedApplication} />,
  },
  {
    path: '/oauth2/redirect/google',
    element: <AuthLayout component={GoogleAuthRedirect} />,
  },
];
