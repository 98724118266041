import { useFlags } from 'launchdarkly-react-client-sdk';

export type FleetType = 'fleet' | 'retail-non-fleet' | 'wholesale-non-fleet';
export const ANONYMOUS_USER_KEY = '00000000-0000-0000-0000-000000000000';

export enum Feature {
  CAMERA_PROGRAM = 'cameraProgram',
  CANNED_EMAILS = 'cannedEmail',
  CLEARANCE_UW_CHANGES = 'clearanceUwChanges',
  ENDORSEMENT_REVIEW_AUTOMATED_PRICING = 'endorsementReviewAutomatedPricing',
  FLEET_TYPE = 'fleetType',
  MINIMUM_MILEAGE_GUARANTEE = 'minimumMileageGuarantee',
  NEGOTIATED_RATES_STATE = 'negotiatedRatesState',
  NF_MVR_UPDATE = 'nfMvrEdit',
  REVIEW_READINESS = 'reviewReadiness',
  SHOW_PIBIT_DATA = 'showPibitData',
  Target_PRICING = 'targetPricing',
  TELEMATICS_STATUS = 'telematicsStatus',
  UW_POSTHOG = 'uwPosthog',
}

export function useFeatureEnabled<T>(flag: string, fallback?: T) {
  const flags = useFlags();

  if (fallback === undefined) {
    return flags[flag] as T;
  }

  return (flags[flag] as T) ?? fallback;
}
